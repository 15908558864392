<template>
  <div>
    <el-form>
      <template v-if="goods.type !== 0">
        <el-form-item label="第一单奖励金额">
          <el-input-number v-model="form.first_award"></el-input-number>%
        </el-form-item>
        <el-form-item label="第二单奖励金额">
          <el-input-number v-model="form.second_award"></el-input-number>%
        </el-form-item>
        <el-form-item label="第三单奖励金额">
          <el-input-number v-model="form.third_award"></el-input-number>%
        </el-form-item>
      </template>
      <template v-else>
        <el-form-item label="直推奖励金额">
          <el-input-number v-model="form.normal_invite_award"></el-input-number>
        </el-form-item>
      </template>
      <el-form-item>
        <el-button @click="submit" type="primary">提交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "inviteAreaAgent",
  props: {
    goods: {
      type: Object,
      default() {
        return {
          id:0
        }
      }
    },
  },
  data() {
    return {
      form:{
        goods_id:0,
        first_award:0,
        second_award:0,
        third_award:0,

        normal_invite_award:0,
      },
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    submit(){
      this.$api.shop.goods.inviteRuleEdit(this.form).then(()=>{
        this.$message.success("操作成功");
        this.load();
      })
    },
    load(){
      this.$api.shop.goods.inviteRule({goods_id:this.goods.id}).then(res=>{
        this.form = res;
      })
    }
  }
}
</script>

<style scoped>

</style>