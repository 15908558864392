<template>
  <div>
    <el-table :data="showBannerList">
      <el-table-column label="图片">
        <template #default="s">
          <img :src="s.row | tomedia" style="width: 2rem;height: 2rem" alt="">
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template #header>
          <el-button @click="add">添加图片</el-button>
          <el-button @click="$emit('form-submit')" type="primary">保存提交</el-button>
          <y_upload_img_choose ref="upload"></y_upload_img_choose>
        </template>
        <template #default="s">
          <el-button @click="remove(s.$index)">移除</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import y_upload_img_choose from "@/components/y_upload/y_upload_img_choose";
export default {
  name: "banner",
  components:{y_upload_img_choose},
  model:{
    prop:"modelVal",
    event:'change'
  },
  props:{
    modelVal:{
      type:String,
      default:"",
    },
  },
  computed:{
    showBannerList(){
      if(!this.modelVal)return [];
      return JSON.parse(this.modelVal);
    },
  },
  methods:{
    remove(index){
      let o = [...this.showBannerList];
      o.splice(index,1);
      this.$emit("change",JSON.stringify(o));
    },
    add(){
      this.$refs.upload.showUpload(e=>{
        let o = [...this.showBannerList];
        o.push(e)
        this.$emit("change",JSON.stringify(o));
      })
    },
  }
}
</script>

<style scoped>

</style>