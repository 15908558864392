import { render, staticRenderFns } from "./inviteAreaAgent.vue?vue&type=template&id=a6ced6c6&scoped=true&"
import script from "./inviteAreaAgent.vue?vue&type=script&lang=js&"
export * from "./inviteAreaAgent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a6ced6c6",
  null
  
)

export default component.exports