<template>
  <div>
    <el-form>
      <el-form-item label="代理奖励金额">
        <el-input-number v-model="form.agent_award"></el-input-number>
      </el-form-item>
      <el-form-item label="老板奖励金额">
        <el-input-number v-model="form.boos_award"></el-input-number>
      </el-form-item>
      <el-form-item label="总监奖励金额">
        <el-input-number v-model="form.chief_award"></el-input-number>
      </el-form-item>
      <el-form-item>
        <el-button @click="submit" type="primary">提交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "invite",
  props: {
    goods: {
      type: Object,
      default() {
        return {
          id:0
        }
      }
    },
  },
  data() {
    return {
      form:{
        goods_id:0,
        agent_award:0,
        boos_award:0,
        chief_award:0
      },
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    submit(){
      this.$api.shop.goods.inviteRuleEdit(this.form).then(()=>{
        this.$message.success("操作成功");
        this.load();
      })
    },
    load(){
      this.$api.shop.goods.inviteRule({goods_id:this.goods.id}).then(res=>{
        this.form = res;
      })
    }
  }
}
</script>

<style scoped>

</style>