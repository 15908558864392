<template>
  <div >
    <el-select v-model="value">
      <el-option v-for="(v,k) in options" :key="k" :value="v.id" :label="v.title"></el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  name: "y_choose_shipping",
  model:{
    prop:"modelVal",
    event:'change'
  },
  props:{
    modelVal: {
      type:Number,
      default:0
    },
    mode:{
      type:String,
      default: ""
    }
  },
  watch:{
    modelVal(){
      this.value = this.modelVal
    },
    value(){
      this.$emit('change',this.value);
    },
  },
  data(){
    return{
      value: this.modelVal,
      options:[],
    }
  },
  mounted() {
    this.loadOption();
  },
  methods:{
    loadOption(){
      this.$api.shop.goods.shippingSearch({page:-1}).then(res=>{
        this.options = res.list;
        this.options.unshift({id:0,title:"未选择"})
      })
    },
  }
}
</script>