<template>
  <div>
    <div class="flex-def flex-cCenter flex-zBetween" style="margin-bottom: 1rem">
      <div class="y-desc" style="font-size: 1rem">商品信息</div>
      <el-button @click="$router.back()" type="text" size="mini">返回列表</el-button>
    </div>
    <el-card style="min-height: 45rem">
      <el-tabs :before-leave="tabsChange" v-model="tabsActive">
        <el-tab-pane label="基础信息" name="basic">
          <basic @theme-change="themeChange" v-model="form" @form-submit="submit"></basic>
        </el-tab-pane>
        <el-tab-pane label="商品详情" name="detail">
          <detail v-model="form" @form-submit="submit"></detail>
        </el-tab-pane>
        <el-tab-pane label="详情页轮播图" name="banner">
          <banner @reload="load" v-model="form.banner" @form-submit="submit"></banner>
        </el-tab-pane>
        <el-tab-pane label="库存/规格" name="spec">
          <spec @reload="load" v-model="form" @form-submit="submit"></spec>
        </el-tab-pane>
        <el-tab-pane label="邀请奖励" v-if="modeAreaAgentInviteSuc || modeChainInviteSuc" name="invite">
          <template v-if="form.id > 0">
            <inviteAreaAgent v-if="modeAreaAgentInviteSuc" :goods="form"></inviteAreaAgent>
            <invite v-if="modeChainInviteSuc" :goods="form"></invite>
          </template>
        </el-tab-pane>
      </el-tabs>
    </el-card>
  </div>
</template>

<script>
import basic from "@/view/goods/edit/basic";
import detail from "@/view/goods/edit/detail";
import spec from "@/view/goods/edit/spec";
import banner from "@/view/goods/edit/banner";
import invite from "@/view/goods/edit/invite";
import inviteAreaAgent from "@/view/goods/edit/inviteAreaAgent";
export default {
  components: {
    basic,
    detail,
    spec,
    banner,
    invite,
    inviteAreaAgent,
  },
  name: "edit",
  data() {
    return {
      type_opt: [
        {label: '普通商品', value: 0},
        {label: '升级商品', value: 1},
        {label: '免费商品', value: 2},
      ],
      form: {
        sort: 0,
        title: "",
        share_desc:"",
        cate_id: 0,
        cate_parent_id: 0,
        fake_sales: 0,
        type: 0,
        fee: 0,
        original_price: 0,
        stock: 0,
        index_pic:"",
        pic: "",
        detail: "",
        status: false,
        delivery_fee: 0,
        spec:false,
        scope:"",
        banner:"",
        recommend:false,
        shipping_id:0,
      },
      theme_ids:[],
      tabsActive: "basic"
    }
  },
  computed:{
    modeChainInviteSuc(){
      return this.$store.state.sys.global.addons.chain && this.form.type === 0
    },
    modeAreaAgentInviteSuc(){
      return this.$store.state.sys.global.addons.area_agent && [0,3,4,5,6,7].includes(this.form.type);
    },
  },
  mounted() {
    if (this.$route.query.id) {
      this.load(parseInt(this.$route.query.id));
    }
  },
  methods: {
    load(edit_id) {
      this.$api.shop.goods.one({id: parseInt(edit_id)}).then(res => {
        this.form = {...res}
      })
    },
    themeChange(val) {
      this.theme_ids = val
    },
    submit() {
      this.$api.shop.goods.edit({
        goods:this.form,
        theme_ids:this.theme_ids,
      }).then(() => {
        this.$message.success("操作成功");
        this.$router.back();
      })
    },
    tabsChange(e) {
      let arr = ["invite","spec"]
      if (arr.includes(e) && !this.form.id) {
        this.$message.error("请先创建商品信息")
        return false
      }
    },
  }
}
</script>

<style scoped>

</style>